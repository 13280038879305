<template>
  <div class="verification">
    <div class="verification-header">
      <logo />
    </div>
    <div class="verification-content" v-if="!isLinkSent">

      <h2 class="verification-content-title text-center">Verify your identity</h2>
      <p>
        To continue registration you must provide document. Please prepare your Passport or ID Card and choose the verification assistance option.
      </p>

      <button class="btn btn-yellow btn-block" @click="send(0)" data-test="button-camera">
        <icon-camera color="#000" size="24" />
        <span>Open web camera</span>
      </button>
      <button class="btn btn-default btn-block" @click="send(1)" :disabled="isDeviceLink">
        <icon-another-device color="#000" size="24" data-test="button-another-device"/>
        <span>Use another device</span>
      </button>
    </div>
    <div class="verification-content" v-else>
      <div class="verification-content-title text-center">
        Use smartphone for verification
      </div>

      <p class="marked text-center">You will receive a link to <br/><strong>{{ registrationData.user.attributes.email }}</strong></p>
      <p>Open it from your smartphone and verify your identity through a smartphone.</p>
      <p>After verification is completed, the page will automatically reload</p>

      <button class="btn btn-yellow btn-block" @click="checkStatus()" data-test="button-check-status">
        <icon-reload color="#000" size="24" :class="{animated: isCheckIsProgress}"/>
        <span>Check status</span>
      </button>

    </div>
    <div class="verification-btn-section text-right" v-if="isDevelopmentEnv">
      <button @click="$emit('next-step')" type="button" class="btn-next" data-test="submit">
        <span>Skip identity verification</span>
      </button>
    </div>
    <div class="verification-footer">
      If you have technical issues please contact our support at <a :href="'mailto:' + settings.email_support">{{ settings.email_support }}</a>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/images/Logo";
import IconCamera from "@/assets/icons/IconCamera";
import IconAnotherDevice from "@/assets/icons/IconAnotherDevice";
import IconReload from '@/assets/icons/IconReload';
import {mapState} from "vuex";

export default {
  name: 'Verification',
  components: {
    Logo,
    IconCamera,
    IconReload,
    IconAnotherDevice,
  },
  data () {
    return {
      isLinkSent: false,
      isCheckIsProgress: false
    }
  },
  computed: {
    ...mapState({
      settings: state => state.init.settings,
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data
    }),

    /**
     * Если ссылка для устройств, блочим кнопку с другого устройства
     * @return {boolean}
     */
    isDeviceLink () {
      return /device$/.test(this.$route.path)
    },
    isDevelopmentEnv () {
      const environments = ['development', 'testing']
      return environments.includes(process.env.NODE_ENV)
    }
  },
  methods: {
    send (sendEmail) {
      this.$sdk.registration.verification.send(this.registrationHash, sendEmail).then(response => {
        if (sendEmail) {
          this.isLinkSent = true
        } else {
          if (response.data.url) {
            window.location.href = response.data.url
          }
        }
      }).catch(reason => {
        console.log(reason)
      })
    },
    checkStatus () {
      this.isCheckIsProgress = true

      this.$sdk.registration.verification.get(this.registrationHash).then(response => {
        if (response.data && response.data.token && response.data.token.length > 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$emit('next-step')
          })
        }
      }).finally( () => {
        setTimeout(() => {
          this.isCheckIsProgress = false
        }, 300)
      })
    }
  }
}
</script>

<style lang="less" src="../../assets/css/verification.less"></style>
